export const environment = {
    production: true,
    hmr       : false,
    api_url: 'https://api.e.cct.tn',
    recaptchaSiteKey: '6Ld7MfgqAAAAAGY-rDesFJcuzRE0YbZegSD2aXZD',
    SENTRY_AUTH_TOKEN: '502331f57af97f287f679d887b69145fab397241a0700ef7fc3613f8d5ffbe69',
    build: '18-03-2025-13-58-19',
    version: require('../../package.json').version

};
